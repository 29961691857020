import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { Empty } from 'google-protobuf/google/protobuf/empty_pb';

import { handleGrpcResponse } from '@agentero/service-errors';

import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';
import { agentFrontendService } from 'packages/services/grpcClients';

const stopImpersonation = (token: string): Promise<void> =>
	new Promise((resolve, reject) => {
		const request = new Empty();

		agentFrontendService.revertImpersonation(
			request,
			{ Authorization: `Bearer ${token}` },
			error => {
				if (error) {
					handleGrpcResponse(reject, error, '');
				}
				resolve();
			}
		);
	});

export const useStopImpersonation = (options?: UseMutationOptions<void, Error, {}, unknown>) => {
	const {
		data: { token }
	} = useAgentResource();

	const { mutate, isLoading } = useMutation<void, Error, {}>(
		() => stopImpersonation(token),
		options
	);
	return { stopImpersonation: mutate, isLoading };
};
